// Vue Router
import VueRouter from 'vue-router'
import AuthService from '@/services/auth.service'

import Cookies from 'js-cookie'
import leads from './leads'

// Layout
const DefaultLayout = () => import('@/layouts/Default')

// Pages
const Index = () => import('@/pages/Index')
const Login = () => import('@/pages/login/Index')
const ValidateToken = () => import('@/pages/validate-token/Index')
const ChangePassword = () => import('@/pages/change-password/Index')
const About = () => import('@/pages/about/Index')

// Error Pages
const Page404 = () => import('@/pages/404')
const Page500 = () => import('@/pages/500')

// Banner Home Pages
const BannersHome = () => import('@/pages/banners-home/Datatable')
const BannerHome = () => import('@/pages/banners-home/View')
const OrderBannerHome = () => import('@/pages/banners-home/Order')

// Banner Part Pages
const BannersPart = () => import('@/pages/banners-part/Datatable')
const BannerPart = () => import('@/pages/banners-part/View')
const OrderBannerPart = () => import('@/pages/banners-part/Order')

// Banner Insurance Pages
const BannersInsurance = () => import('@/pages/banners-insurance/Datatable')
const BannerInsurance = () => import('@/pages/banners-insurance/View')
const OrderBannerInsurance = () => import('@/pages/banners-insurance/Order')

// Banner Financing Pages
const BannersFinancing = () => import('@/pages/banners-financing/Datatable')
const BannerFinancing = () => import('@/pages/banners-financing/View')
const OrderBannerFinancing = () => import('@/pages/banners-financing/Order')

// Banner Part Pages
const BannersAbout = () => import('@/pages/banners-about/Datatable')
const BannerAbout = () => import('@/pages/banners-about/View')

// Contact Subject
const ContactSubjectDatatable = () =>
  import('@/pages/contact-subjects/Datatable')
const ContactSubjectView = () => import('@/pages/contact-subjects/View')

// Maintenance Service
const MaintenanceServiceDatatable = () =>
  import('@/pages/maintenance-services/Datatable')
const MaintenanceServiceView = () => import('@/pages/maintenance-services/View')

// Signature Service
const SignatureServiceDatatable = () => import('@/pages/signature/Datatable')
const SignatureServiceView = () => import('@/pages/signature/View')

// Signature Job Service
const SignatureJobServiceDatatable = () =>
  import('@/pages/signature-job/Datatable')
const SignatureJobServiceView = () => import('@/pages/signature-job/View')

// Unity Pages
const UnityDatatable = () => import('@/pages/unities/Datatable')
const UnityView = () => import('@/pages/unities/View')

// Consortium Pages
const ConsortiumDatatable = () => import('@/pages/consortiums/Datatable')
const ConsortiumView = () => import('@/pages/consortiums/View')

// Icon Pages
const IconDatatable = () => import('@/pages/icons/Datatable')
const IconView = () => import('@/pages/icons/View')

// Model Pages
const ModelDatatable = () => import('@/pages/models/Datatable')
const ModelView = () => import('@/pages/models/View/index.vue')
const OrderModels = () => import('@/pages/models/Order')

// Vehicle Pages
const VehicleDatatable = () => import('@/pages/vehicles/Datatable')
const VehicleView = () => import('@/pages/vehicles/View/index.vue')
const OrderVehicles = () => import('@/pages/vehicles/Order')

// Users Pages
const Users = () => import('@/pages/users/Datatable')
const User = () => import('@/pages/users/View')

// News Pages
const NewsDatatable = () => import('@/pages/news/Datatable')
const NewsView = () => import('@/pages/news/View')

// News Category Pages
const NewsCategoryDatatable = () => import('@/pages/news-category/Datatable')
const NewsCategoryView = () => import('@/pages/news-category/View')

// Work With Us
// const WorkUsDatatable = () => import('@/pages/work-with-us/Datatable')
// const WorkUsView = () => import('@/pages/work-with-us/View')

// Permissions Pages
const Permissions = () => import('@/pages/permissions/Datatable')
const Permission = () => import('@/pages/permissions/View')

// SEO
const SEO = () => import('@/pages/seo')

// Contacts
const Contact = () => import('@/pages/contacts/Index')

// Settings
const Settings = () => import('@/pages/settings')

// Cookies
const CookiesPage = () => import('@/pages/cookies/Index')

// Render Component
const RenderComponent = { render: (c) => c('router-view') }

// Router
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/change-password/:token',
      name: 'Alterar senha',
      component: ChangePassword
    },
    {
      path: '/change-password/expired/:token',
      name: 'Alterar senha expirada',
      component: ChangePassword
    },
    {
      path: '/validate-token',
      name: 'Validar o token de acesso',
      component: ValidateToken
    },
    {
      path: '/',
      name: 'Home',
      redirect: '/dashboard',
      component: DefaultLayout,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Index
        },
        {
          path: 'seo',
          name: 'SEO',
          component: SEO
        },
        {
          path: 'cookies',
          name: 'Cookies',
          component: CookiesPage
        },
        {
          path: 'settings',
          name: 'Configurações',
          component: Settings
        },
        {
          path: 'news',
          name: 'Notícias',
          redirect: 'news/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: NewsDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Notícia',
              component: NewsView
            },
            {
              path: 'edit/:id',
              name: 'Editar Notícia',
              component: NewsView
            }
          ]
        },
        {
          path: 'news-category',
          name: 'Categoria de Notícias',
          redirect: 'news-category/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: NewsCategoryDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Categoria',
              component: NewsCategoryView
            },
            {
              path: 'edit/:id',
              name: 'Editar Categoria',
              component: NewsCategoryView
            }
          ]
        },
        // {
        //   path: 'work-with-us',
        //   name: 'Trabalhe Conosco',
        //   redirect: 'work-with-us/',
        //   component: RenderComponent,
        //   children: [
        //     {
        //       path: '/',
        //       component: WorkUsDatatable
        //     },
        //     {
        //       path: '/work-with-us/edit/:id',
        //       name: 'Visualizar',
        //       component: WorkUsView
        //     }
        //   ]
        // },
        {
          path: 'banners-home',
          name: 'Banner - Home',
          redirect: 'banners-home/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: BannersHome
            },
            {
              path: 'create',
              name: 'Cadastrar Banner Home',
              component: BannerHome
            },
            {
              path: 'edit/:id',
              name: 'Editar Banner Home',
              component: BannerHome
            },
            {
              path: 'order',
              name: 'Ordenar Banner Home',
              component: OrderBannerHome
            }
          ]
        },
        {
          path: 'banners-part',
          name: 'Banners - Peças',
          redirect: 'banners-part/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: BannersPart
            },
            {
              path: 'create',
              name: 'Cadastrar Banner Peça',
              component: BannerPart
            },
            {
              path: 'edit/:id',
              name: 'Editar Banner Peça',
              component: BannerPart
            },
            {
              path: 'order',
              name: 'Ordenar Banner Peça',
              component: OrderBannerPart
            }
          ]
        },
        {
          path: 'banners-insurance',
          name: 'Banners - Seguros',
          redirect: 'banners-insurance/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: BannersInsurance
            },
            {
              path: 'create',
              name: 'Cadastrar Banner Seguro',
              component: BannerInsurance
            },
            {
              path: 'edit/:id',
              name: 'Editar Banner Seguro',
              component: BannerInsurance
            },
            {
              path: 'order',
              name: 'Ordenar Banner Seguro',
              component: OrderBannerInsurance
            }
          ]
        },
        {
          path: 'banners-financing',
          name: 'Banners - Iveco Capital',
          redirect: 'banners-financing/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: BannersFinancing
            },
            {
              path: 'create',
              name: 'Cadastrar Banner Iveco Capital',
              component: BannerFinancing
            },
            {
              path: 'edit/:id',
              name: 'Editar Banner Iveco Capital',
              component: BannerFinancing
            },
            {
              path: 'order',
              name: 'Ordenar Banner Iveco Capital',
              component: OrderBannerFinancing
            }
          ]
        },
        {
          path: 'banners-about',
          name: 'Banners - Sobre Nós',
          redirect: 'banners-about/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: BannersAbout
            },
            {
              path: 'edit/:id',
              name: 'Editar Banner Sobre Nós',
              component: BannerAbout
            }
          ]
        },
        {
          path: 'contact-subjects',
          name: 'Contato - Assuntos',
          redirect: 'contact-subjects/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: ContactSubjectDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Assunto',
              component: ContactSubjectView
            },
            {
              path: 'edit/:id',
              name: 'Editar Assunto',
              component: ContactSubjectView
            }
          ]
        },
        {
          path: 'maintenance-services',
          name: 'Manutenção - Serviços',
          redirect: 'maintenance-services/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: MaintenanceServiceDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Serviço',
              component: MaintenanceServiceView
            },
            {
              path: 'edit/:id',
              name: 'Editar Serviço',
              component: MaintenanceServiceView
            }
          ]
        },
        {
          path: 'signature',
          name: 'Assinatura de e-mail',
          redirect: 'signature/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: SignatureServiceDatatable
            },
            {
              path: 'view/:id',
              name: 'Detalhes - Assinatura de e-mail',
              component: SignatureServiceView
            }
          ]
        },
        {
          path: 'signature-job',
          name: 'Cargos para a assinatura',
          redirect: 'signature-job/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: SignatureJobServiceDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Cargos para a assinatura',
              component: SignatureJobServiceView
            },
            {
              path: 'edit/:id',
              name: 'Editar Cargos para a assinatura',
              component: SignatureJobServiceView
            }
          ]
        },
        {
          path: 'contacts',
          name: 'Contato',
          redirect: 'contacts/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              name: '',
              component: Contact
            },
            {
              path: ':id',
              name: 'Detalhes da mensagem',
              component: Contact
            }
          ]
        },

        {
          path: 'icons',
          name: 'Ícones',
          redirect: 'icons/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: IconDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar ícone',
              component: IconView
            },
            {
              path: 'edit/:id',
              name: 'Editar ícone',
              component: IconView
            }
          ]
        },
        {
          path: 'models',
          name: 'Modelos',
          redirect: 'models/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: ModelDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Modelo',
              component: ModelView
            },
            {
              path: 'edit/:id',
              name: 'Editar Modelo',
              component: ModelView
            },
            {
              path: 'order',
              name: 'Ordenar Modelos',
              component: OrderModels
            }
          ]
        },
        {
          path: 'vehicles',
          name: 'Veículos',
          redirect: 'vehicles/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: VehicleDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Veículo',
              component: VehicleView
            },
            {
              path: 'edit/:id',
              name: 'Editar Veículo',
              component: VehicleView
            },
            {
              path: 'order',
              name: 'Ordenar Veículos',
              component: OrderVehicles
            }
          ]
        },
        {
          path: 'unity',
          name: 'Unidades',
          redirect: 'unity/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: UnityDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Unidade',
              component: UnityView
            },
            {
              path: 'edit/:id',
              name: 'Editar Unidade',
              component: UnityView
            }
          ]
        },
        {
          path: 'consortiums',
          name: 'Consócios',
          redirect: 'consortium/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              component: ConsortiumDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Consócio',
              component: ConsortiumView
            },
            {
              path: 'edit/:id',
              name: 'Editar Consócio',
              component: ConsortiumView
            }
          ]
        },
        {
          path: 'users',
          name: 'Usuários',
          redirect: 'users/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              name: '',
              component: Users
            },
            {
              path: 'create',
              name: 'Novo Usuário',
              component: User
            },
            {
              path: 'edit/:id',
              name: 'Editar Usuario',
              component: User
            }
          ]
        },
        {
          path: 'permissions',
          name: 'Permissões',
          redirect: 'permissions/',
          component: RenderComponent,
          children: [
            {
              path: '/',
              name: '',
              component: Permissions
            },
            {
              path: 'create',
              name: 'Novo Papel',
              component: Permission
            },
            {
              path: 'edit/:id',
              name: 'Editar Papeis',
              component: Permission
            }
          ]
        },
        {
          path: 'about',
          name: 'Sobre',
          component: About
        },
        ...leads
      ]
    },
    {
      path: '/500',
      name: '500',
      component: Page500
    },
    {
      path: '*',
      name: '404',
      component: Page404
    }
  ]
})

/**
 // Authentication Logic //
 // Uncomment when API is ready to use //
*/
router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login']

  if (to.path.includes('/change-password')) {
    publicPages.push(to.path)
  }

  const authRequired = !publicPages.includes(to.path)

  const loggedIn = Cookies.getJSON('base:user')
  const userId = Cookies.get('base:userId')
  const token = Cookies.get('base:token')
  const userHaveTwoFactorAuth = Cookies.get('base:userHaveTwoFactorAuth')

  if (userHaveTwoFactorAuth && to.path !== '/validate-token') {
    // Cookies.remove('base:user')
    // Cookies.remove('base:userId')
    // Cookies.remove('base:link')
    // next('/validate-token')
  }

  if (loggedIn) {
    await router.app.$store.dispatch('auth/login', token)
    // ************* REGRA QUE LIMITA O ACESSO AO MANAGER **************
    // if (loggedIn.data.user.roles[0].id !== 1) {
    //   Cookies.remove('base:user')
    //   Cookies.remove('base:token')
    //   Cookies.remove('base:userId')
    //   Cookies.remove('base:link')
    //   next('/login')
    // }
    if (loggedIn.pass_expired === 1) {
      Cookies.remove('base:user')
      Cookies.remove('base:userId')
      Cookies.remove('base:link')
      Cookies.set('base:userId', loggedIn.data.user.id)
      const token = await AuthService.passExpired(loggedIn.data.user.id)
      next(`/change-password/expired/${token}`)
    }
  }

  if (authRequired && !loggedIn) {
    Cookies.set('base:link', to.path)
    next('/login')
  } else if (from.path === '/login' && !userId && loggedIn) {
    const link = Cookies.get('base:link')

    Cookies.remove('base:link')

    if (link) {
      next(`${link}`)
    }

    next()
  }

  router.app.$root.$emit('update:routes')

  next()
})

export default router
