/**
 * return string contains sentense of string list
 * @param {Array<string>} list
 * @param {string} connector
 * @returns {string}
 */

export const joinListToSentense = (list, connector = 'e') => {
  const find = /(?<leftover>.+), (?<last>.+$)/
  const replace = `$<leftover> ${connector} $<last>`

  return list
    .map((item) => `${item}`.trim())
    .filter((item) => !!item.length)
    .join(', ')
    .replace(find, replace)
}
