import Axios from './axios.service'
import AxiosOriginal from 'axios'
import Cookies from 'js-cookie'

/**
 * This class handles all auth interactions
 *
 * @class AuthService
 */
class AuthService {
  /**
   * Receive data of user to login in on system
   *
   * @method return
   *
   * @param  {Object} user - Email and Password
   *
   * @return {object} instance o user and spread token
   */
  async login(user) {
    const response = await Axios.post('/user/login', user)

    if (response.data.token) {
      const user = response.data.user
      const token = response.data.token

      Cookies.set('base:user', user, {
        expires: 365
      })
      Cookies.set('base:token', token.token, {
        expires: 365
      })
      Cookies.set('base:refreshToken', token.refreshToken, {
        expires: 365
      })
      Cookies.set(
        'base:userHaveTwoFactorAuth',
        token.user_have_two_factor_auth,
        {
          expires: 365
        }
      )

      return {
        user,
        token: token.token,
        user_have_two_factor_auth: token.user_have_two_factor_auth
      }
    }
  }

  /**
   * Send token to validate user to login
   *
   * @method return
   *
   * @param  {Object} request - Token
   *
   * @return {object} instance o user and spread token
   */
  async validateToken(request) {
    try {
      const response = await Axios.post('/user/validate-token', request)

      if (response.data.token) {
        const user = response.data.user
        const token = response.data.token

        Cookies.set('base:user', user, {
          expires: 365
        })
        Cookies.set('base:token', token.token, {
          expires: 365
        })
        Cookies.set(
          'base:userHaveTwoFactorAuth',
          token.user_have_two_factor_auth,
          {
            expires: 365
          }
        )

        return {
          user,
          token: token.token,
          user_have_two_factor_auth: token.user_have_two_factor_auth
        }
      }
    } catch (error) {
      return error
    }
  }

  /**
   * Remove all cookies of user
   *
   * @method handle
   *
   * @param  {null}
   *
   * @return {null}
   */
  logout() {
    const cookies = [
      'base:user',
      'base:token',
      'base:refreshToken',
      'base:userId',
      'base:link',
      'base:userHaveTwoFactorAuth'
    ]

    cookies.forEach((cookie) => {
      Cookies.remove(cookie)
    })
  }

  /**
   * Send the email of a user who needs to have their password reset
   *
   * @method async
   *
   * @param  {string} email - email
   *
   * @return {boolean} true or false
   */
  async passReset(email) {
    try {
      const response = await Axios.post('/lost-password', { login: email })
      return response.status
    } catch (error) {
      return error.response.status
    }
  }

  /**
   * Receives the current token and sends it to whoaim to receive updated user data
   * Is necessary pass token on first interaction to fix login issue on API
   *
   * @method async handle
   *
   * @param  {null}
   *
   * @return {object} response of api with user data (user, roles and permissions)
   */
  async whoiam() {
    try {
      const response = await Axios.get('/whoiam')
      const userData = response.data

      Cookies.set('base:user', userData, {
        expires: 365
      })

      return response
    } catch (error) {
      return error.response.status
    }
  }

  /**
   * If the password is expired here, a signal is sent to the API to renew it
   *
   * @method async handle
   *
   * @param  {integer} id
   *
   * @return {Object} response of API
   */
  async passExpired(id) {
    try {
      const response = await Axios.get(`/expired/${id}`)
      return response.data
    } catch (error) {
      return error.response.status
    }
  }

  /**
   * Set a new password for the selected user
   *
   * @method async handle
   *
   * @param  {Object} data - spread of data
   *
   * @return {boolean} Response of status
   */
  async setNewPassword(data) {
    try {
      const response = await Axios.put('/lost-password/new-password', {
        ...data
      })
      return response.status
    } catch (error) {
      return error.response.status
    }
  }

  /**
   * @todo - Check the real need for this method, it seems that the cookie could
   * have been used
   *
   * Used within the file uploader
   *
   * @method handle
   *
   * @param  {null}
   *
   * @return {string} Token with bearear on object Authorization
   */
  getAuth() {
    const token = Cookies.get('base:token')

    if (token) {
      return { Authorization: 'Bearer ' + token }
    } else {
      return {}
    }
  }

  /**
   * Method ported from axios service, where it receives an instance of request
   * and if the interceptor retrieves a 401 from a page other than login, a new
   * token is generated
   *
   * @method handle
   *
   * @param  {Object} Request of user
   *
   * @return {Obejct} Original axios instance
   */
  renewToken(request) {
    const refresh_token = Cookies.get('base:refreshToken')

    return Axios.post('/user/refreshtoken', { refresh_token })
      .then((response) => {
        Cookies.set('base:token', response.data.token, {
          expires: 365
        })

        AxiosOriginal.defaults.headers.common.Authorization =
          request.headers.Authorization = `Bearer ${response.data.token}`

        return AxiosOriginal(request)
      })
      .catch((error) => {
        this.logout()
        window.location.href = '/login'
        return Promise.reject(error)
      })
  }
}

/**
 * Export class AuthService
 */
export default new AuthService()
