const mutations = {
  SET_FORM(state, form) {
    state.form = form
  },

  SET_MEDIAS_FORM(state, form) {
    const data = {}
    Object.keys(form).forEach((key) => (data[key] = [...form[key]]))
    state.mediasForm = data
  },

  SET_SETTINGS(state, settings) {
    state.settings = settings
  },

  SET_PROCESSING(state, processing) {
    state.processing = processing
  },

  SET_MEDIAS(state, medias) {
    state.medias = medias
  },

  SET_SELECTED_COOKIE_IMAGE(state, image) {
    state.selectCookieImage = image
  }
}

export default mutations
