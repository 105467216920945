import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Plugins from './plugins'
import store from './store/index'
import * as icons from '@coreui/icons'

Vue.config.productionTip = false

/*
 * SCSS
 */
require('./assets/scss/main.scss')

/*
 * PLUGINS
 */
Vue.use(Plugins)

new Vue({
  store,
  render: (h) => h(App),
  router,
  icons
}).$mount('#app')
