import Axios from 'axios'
import Cookies from 'js-cookie'
import AuthService from './auth.service'

/**
 * Renew access token if interceptor gets HTTP code 401 (unauthorized) if any error
 * function throws a new error and redirects to login
 *
 * @method report
 *
 * @param  {Object} Axios Request
 *
 * @return {Class}
 */
async function getNewAccessToken(request) {
  AuthService.renewToken(request)
}

/**
 * Instance of Cookies and axios to set default values to persist of all HTTP requests
 * Note: Authorization header is a not required on any request of api, in case the
 * method is authenticated, the header will be asked if nothing will change
 */
const api = Axios.create({
  baseURL: process.env.VUE_APP_API_URL // baseURL is setted on .env
})

/**
 * Add a request interceptor to insert Authorization token
 */
api.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${Cookies.get('base:token')}`
  return request
})

/**
 * Processes the global response interceptors, if any returned 401 is called this
 * getNewAccessToken of same scope
 */
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response) {
      if (
        error.response.status === 401 &&
        !window.location.pathname.includes('/login')
      ) {
        originalRequest._retry = true

        return getNewAccessToken(originalRequest)
      }
    }

    return Promise.reject(error)
  }
)

/**
 * Export instance of AXIOS with header (Authorization) and baseURL
 */
export default api
