import service from '@/services/settings.service'
import { sleep } from '@/utils'

const COOKIES_NOTIFICATION_TEXT =
  'Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços. Ao utilizar nossos serviços, você concorda com tal monitoramento.'

const ACCEPTENCE_TEXT =
  'Aceito compartilhar minhas informações e estou de acordo com as <a href="/politicas-de-privacidade" target="_blank">Políticas de Privacidade</a> do site.'

const getValidData = ({ form }) => ({
  ...form,
  acceptance_text: form.acceptance_text || ACCEPTENCE_TEXT,
  cookies_notification_text:
    form.cookies_notification_text || COOKIES_NOTIFICATION_TEXT
})

const getValidMedias = ({ mediasForm }) => ({
  background: mediasForm.background.length ? mediasForm.background[0].id : null,
  whiteLogo: mediasForm.whiteLogo.length ? mediasForm.whiteLogo[0].id : null,

  coloredLogo: mediasForm.coloredLogo.length
    ? mediasForm.coloredLogo[0].id
    : null,

  whiteLogoReduced: mediasForm.whiteLogoReduced.length
    ? mediasForm.whiteLogoReduced[0].id
    : null
})

const actions = {
  load({ dispatch }) {
    dispatch('loadSettings')
    dispatch('loadMedias')
  },

  async loadSettings({ getters, commit }) {
    const settings = await service.listAll()
    const settingsValues = { ...getters.settings, ...settings }
    commit('SET_SETTINGS', settingsValues)
    commit('SET_FORM', settingsValues)
  },

  async loadMedias({ getters, commit }) {
    const medias = await service.listAllMedias()
    const mediaValues = { ...getters.medias, ...medias }
    commit('SET_MEDIAS', mediaValues)
    commit('SET_MEDIAS_FORM', mediaValues)
  },

  async store({ getters, dispatch, commit }) {
    if (getters.processing) return
    commit('SET_PROCESSING', true)

    try {
      const settings = getValidData(getters)
      const medias = getValidMedias(getters)

      await Promise.all([
        service.setSettings(settings),
        service.setSettingsMedia(medias)
      ])

      await sleep(10)
      await dispatch('load')

      return true
    } catch (e) {
      console.log(e)
      return false
    } finally {
      commit('SET_PROCESSING', false)
    }
  },

  input({ commit, getters }, data) {
    commit('SET_FORM', { ...getters.form, ...data })
  },

  reset({ commit, getters }) {
    commit('SET_FORM', { ...getters.settings })
    commit('SET_MEDIAS_FORM', { ...getters.medias })
  },

  selectCookiePosition({ dispatch, commit }, position) {
    commit('SET_SELECTED_COOKIE_IMAGE', `positions/previews/${position}`)
    dispatch('input', { cookies_notification_position: position })
  },

  selectCookieController({ dispatch, commit }, controller) {
    commit('SET_SELECTED_COOKIE_IMAGE', `controllers/previews/${controller}`)
    dispatch('input', { cookies_notification_controller: controller })
  },

  setMedias({ commit }, medias) {
    commit('SET_MEDIAS', medias)
  }
}

export default actions
