const Leads = () => import('@/pages/leads/Datatable')
const View = () => import('@/pages/leads/View')

const leads = [
  { name: 'Modelos', type: 'models' },
  { name: 'Veículos', type: 'vehicles' },
  { name: 'Peças', type: 'parts' },
  { name: 'Consórcio', type: 'consortiums' },
  { name: 'Plano de manutenção', type: 'maintenance-plans' },
  { name: 'Agendamento', type: 'review-schedules' },
  { name: 'Top Drivers', type: 'top-drivers' },
  { name: 'Iveco Connect', type: 'iveco-connect' },
  { name: 'Seguros', type: 'insurances' },
  { name: 'Iveco Capital', type: 'financing' }
]

export default leads.map(({ name, type }) => ({
  path: `leads/${type}`,
  name: `Leads - ${name}`,
  redirect: `leads/${type}/`,
  component: {
    render(c) {
      return c('router-view')
    }
  },
  children: [
    {
      path: '/',
      component: Leads,
      meta: { name, type }
    },
    {
      path: 'view/:id',
      name: `Detalhes - Lead ${name}`,
      component: View,
      meta: { name, type }
    }
  ]
}))
