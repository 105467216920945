const getters = {
  form(state) {
    return { ...state.form }
  },

  mediasForm(state) {
    return { ...state.mediasForm }
  },

  settings(state) {
    return { ...state.settings }
  },

  processing(state) {
    return state.processing
  },

  medias(state) {
    return { ...state.medias }
  },

  selectCookieImage(state) {
    return state.selectCookieImage
  },

  whiteLogo(state) {
    const [logo] = state.medias.whiteLogo
    return logo || null
  },

  whiteLogoReduced(state) {
    const [logo] = state.medias.whiteLogoReduced
    return logo || null
  },

  coloredLogo(state) {
    const [logo] = state.medias.coloredLogo
    return logo || null
  },

  background(state) {
    const [background] = state.medias.background
    return background || null
  },

  isHomolog({ settings }) {
    return settings.environment === 'homolog'
  }
}

export default getters
