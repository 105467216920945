const getInitialSettings = () => ({
  acceptance_text: '',
  cookies_notification_controller: '',
  cookies_notification_position: 'left',
  cookies_notification_text: '',
  cookies_notification_version: 'simple',
  cookies_notification: '',
  environment_under_maintenance: '',
  environment: '',
  facebook: '',
  google_analytics_id: '',
  google_site_verification: '',
  google_tag_manager: '',
  instagram: '',
  linkedin: '',
  phone: '',
  privacy_policy: '',
  raffle_page: '',
  redirect_schedule: '',
  rd_station: '',
  scripts: '',
  terms_of_use: '',
  twitter: '',
  whatsapp: '',
  youtube: ''
})

const getInitialMedias = () => ({
  background: [],
  coloredLogo: [],
  whiteLogo: [],
  whiteLogoReduced: []
})

const getInitialsState = () => ({
  settings: getInitialSettings(),
  medias: getInitialMedias(),
  form: getInitialSettings(),
  mediasForm: getInitialMedias(),
  selectCookieImage: 'positions/previews/left',
  processing: false
})

const state = getInitialsState()

export default state
