/**
 * https://router.vuejs.org/
 * Router and Navigation
 */
import VueRouter from 'vue-router'

/**
 * https://coreui.io/vue/docs/introduction/
 * Template
 */
import CoreuiVue from '@coreui/vue'

/**
 * https://github.com/nuxt/vue-meta
 * Vue Meta
 */
import VueMeta from 'vue-meta'

/**
 * https://github.com/euvl/vue-notification
 * Vue Notification
 */
import Notifications from 'vue-notification'

/**
 * https://github.com/RonaldJerez/vue-input-facade
 * Vue Facade
 */
import InputFacade from 'vue-input-facade'

/**
 * https://www.npmjs.com/package/vue2-filters
 * Vue The Mask
 */
import Vue2Filters from 'vue2-filters'

/**
 * https://github.com/ankurk91/vue-loading-overlay
 * Loader
 */
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

/**
 * https://github.com/vuejs-tips/v-money
 * Vue Mask Money
 */
import money from 'v-money'
/**
 * https://www.npmjs.com/package/v-mask
 * V-Mask
 */
import VueMask from 'v-mask'

export default {
  install: function (Vue) {
    Vue.use(VueRouter)
    Vue.use(CoreuiVue)
    Vue.use(VueMeta, {
      refreshOnceOnNavigation: true
    })
    Vue.use(Notifications)
    Vue.use(InputFacade)
    Vue.use(Vue2Filters)
    Vue.use(VueMask)
    Vue.use(money, {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
      masked: false /* doesn't work with directive */
    })
    Vue.use(Loading, {
      loader: 'spinner',
      width: 64,
      height: 64,
      color: '#5a6779',
      opacity: 0.9,
      isFullPage: false,
      zIndex: 10
    })
  }
}
